.users {

    .table {
        margin: 20px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

        .tableHeader {
            padding: 10px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .tableFiler {
                display: flex;
                align-items: center;
                gap: 10px;
                z-index: 10;
            }
        }
    }
}