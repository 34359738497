.boxDetail {
    .header {
        margin: 20px;
        padding: 20px;
        border-radius: 10px;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            flex-wrap: wrap;

            .item {
                .label {
                    font-size: 14px;
                    color: #999;
                }

                .value {
                    font-size: 18px;
                    color: #111;
                    font-weight: bold;
                }
            }

            .divider {
                height: 50px;
                width: 1px;
                background-color: #111;
            }
        }
    }

    .table {
        margin: 20px;

        .title {
            font-size: 24px;
            color: #111;
            font-weight: bold;
            text-align: center;
        }
    }
}