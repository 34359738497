.chart {
    flex: 5;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;

    font-size: 12px;

    .title {
        font-size: 16px;
        color: gray;
        margin-bottom: 20px;
    }
}