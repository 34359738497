.createEcoBox {
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
    }

    .formElement {
        margin: 20px 0;

        .numberInput {
            width: 100%;
            outline: none;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 16px;
        }
    }
}