.categories {
    .title {
        margin: 20px;
    }

    .createBtn {
        display: flex;
        justify-content: flex-end;
        margin: 20px 20px 0 0;
    }

    .cards-list {
        margin: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        * {
            flex: 1 1 250px;
        }
    }
}