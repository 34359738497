.layout {
  display: flex;

  .container {
    flex: 5;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.sidebar-menu-item-head {
  height: 40px;
}

.sidebar-menu-item {
  height: 40px;
}