.ecoPacketBoxes {
    padding: 20px;

    .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        .card {
            flex: 1;
            max-width: 300px;
            min-width: max-content;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }
    }
}
