.earnings {
    margin: 20px;

    .tableHeader {
        padding: 20px;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;


        .tableFilter {
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: wrap;
            align-items: center;
        }

        .dateFilter {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
            align-items: center;
        }
    }
}